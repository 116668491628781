exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-page-template-js": () => import("./../../../src/templates/AboutPageTemplate.js" /* webpackChunkName: "component---src-templates-about-page-template-js" */),
  "component---src-templates-archived-page-template-js": () => import("./../../../src/templates/ArchivedPageTemplate.js" /* webpackChunkName: "component---src-templates-archived-page-template-js" */),
  "component---src-templates-blog-page-template-js": () => import("./../../../src/templates/BlogPageTemplate.js" /* webpackChunkName: "component---src-templates-blog-page-template-js" */),
  "component---src-templates-blog-post-template-js": () => import("./../../../src/templates/BlogPostTemplate.js" /* webpackChunkName: "component---src-templates-blog-post-template-js" */),
  "component---src-templates-careers-page-template-js": () => import("./../../../src/templates/CareersPageTemplate.js" /* webpackChunkName: "component---src-templates-careers-page-template-js" */),
  "component---src-templates-contact-page-template-js": () => import("./../../../src/templates/ContactPageTemplate.js" /* webpackChunkName: "component---src-templates-contact-page-template-js" */),
  "component---src-templates-country-study-template-js": () => import("./../../../src/templates/CountryStudyTemplate.js" /* webpackChunkName: "component---src-templates-country-study-template-js" */),
  "component---src-templates-event-template-js": () => import("./../../../src/templates/EventTemplate.js" /* webpackChunkName: "component---src-templates-event-template-js" */),
  "component---src-templates-events-page-template-js": () => import("./../../../src/templates/EventsPageTemplate.js" /* webpackChunkName: "component---src-templates-events-page-template-js" */),
  "component---src-templates-in-the-news-template-js": () => import("./../../../src/templates/InTheNewsTemplate.js" /* webpackChunkName: "component---src-templates-in-the-news-template-js" */),
  "component---src-templates-index-template-js": () => import("./../../../src/templates/IndexTemplate.js" /* webpackChunkName: "component---src-templates-index-template-js" */),
  "component---src-templates-mission-page-template-js": () => import("./../../../src/templates/MissionPageTemplate.js" /* webpackChunkName: "component---src-templates-mission-page-template-js" */),
  "component---src-templates-press-page-template-js": () => import("./../../../src/templates/PressPageTemplate.js" /* webpackChunkName: "component---src-templates-press-page-template-js" */),
  "component---src-templates-press-release-template-js": () => import("./../../../src/templates/PressReleaseTemplate.js" /* webpackChunkName: "component---src-templates-press-release-template-js" */),
  "component---src-templates-privacy-page-template-js": () => import("./../../../src/templates/PrivacyPageTemplate.js" /* webpackChunkName: "component---src-templates-privacy-page-template-js" */),
  "component---src-templates-product-page-template-js": () => import("./../../../src/templates/ProductPageTemplate.js" /* webpackChunkName: "component---src-templates-product-page-template-js" */),
  "component---src-templates-research-page-template-js": () => import("./../../../src/templates/ResearchPageTemplate.js" /* webpackChunkName: "component---src-templates-research-page-template-js" */),
  "component---src-templates-research-publication-template-js": () => import("./../../../src/templates/ResearchPublicationTemplate.js" /* webpackChunkName: "component---src-templates-research-publication-template-js" */)
}

